import React from 'react'
import * as PropTypes from "prop-types"
import { Link, graphql } from 'gatsby'
import Layout from "../components/layout"

const propTypes = {
  data: PropTypes.object.isRequired,
}

const ContactList = (data, i) => {
  const ContactInfo = ( instance ) => {
    let item = instance.node

    return (
      <div className="contact-item" key={i}>
        <h3> {item.name} </h3>
        <address> 
          {item.address}  <br />
          {item.zip}      <br />
          {item.phone} <br />
          <a href={"mailto:" + item.mail}>{item.mail}</a>  <br />
          {item.site && 
            <a href={'//' + item.site}>{item.site}</a>
          }
        </address>
      </div>
    )
  }
  
  return (
    <div>
      {data.allContentfulContact.edges.map((item, i) => (
        ContactInfo( item, i )
      ))}
    </div>
  )
}

const About = ({ data }) => {
  const createMarkup = ( content ) => {
    return {__html: content.replace(/\n/g, "<br />")}
  }
  
  return (
    <Layout>
      <div className="about-wrapper">
        <div className="contact-list">
          <h2> Contact </h2>
          {ContactList(data)}
        </div>

        <div className="biography">
          <h2> Biography </h2>
          <p>
            <h3> About </h3>
            Janpeter Muilwijk, Fontainebleau, 1960 <br />
            lives and works in Middelburg, the Netherlands. 
          </p>
          <p>
            <h3> Awards </h3>
              <ul>
                <li> 2005  -  Purmarijn Grafiekprijs </li>
                <li> 1997  -  Paul Citroenprijs </li>
                <li> 1992  -  Koninklijke Prijs voor de Schilderkunst </li>
            </ul>
          </p>
          <p>
            <h3> Selected Texts and Articles </h3>
            <ul>
              {data.allContentfulArtikel.edges.map(({ node }) =>
                <li><Link to={"/" + node.slug}> {node.title} </Link></li>
              )}
            </ul>
          </p>
        </div>
      </div>
    </Layout>
  )
}

About.propTypes = propTypes;

export default About;

export const query = graphql`
  query {
    allContentfulContact (sort: {fields: [index]}) {
      edges {
        node {
          name
          address
          zip
          phone
          mail
          site
        }
      }
    }

    allContentfulArtikel {
      edges {
        node {
          slug
          title
        }
      }
    }
  }
`